<template>
    <div>
        {{getPairs}}
    </div>
</template>

<script>
import pairs from "@/api/pairs.json";
import {pool_list} from '@/constants/pool.js'
import axios from 'axios'
    export default {
        components: {},
        data() {
            return {
            }
        },
        beforeMount() {
            let currentTime = Date.now();
            pairs.updated_at = currentTime;

            let length = pool_list.length;
            for (let i=0; i<length; i++) {
                let base_address = this.$store.state.liquidity.tokenMap.get(pool_list[i].symbol0).address;
                let quote_address = this.$store.state.liquidity.tokenMap.get(pool_list[i].symbol1).address;
                let key = base_address + "_" + quote_address;

                var item = pairs.data[key];
                item.price = this.$store.state.liquidity.pairInfo[i].pair.priceUsd;
                if (this.$store.state.liquidity.pairInfo[i].pair.liquidity.usd != undefined)
                    item.liquidity = (this.$store.state.liquidity.pairInfo[i].pair.liquidity.usd).toString();
                if (this.$store.state.liquidity.pairInfo[i].pair.volume.h1 != undefined)
                    item.volume = (this.$store.state.liquidity.pairInfo[i].pair.volume.h1).toString();
            }
        },
        computed: {
            getPairs() {
                return  pairs;
            },
        },
        methods: {
        }
    }
</script>

<style>
</style>
